import React from "react"

//components
import SEO from "../components/seo"
import Header2 from "../components/header2"
import Footer from "../components/footer"
import Discount from "../components/discount"

// Styles
// import styles from "../styles/pages/success.module.scss"

const DiscountPage = () => (
  <>
    <Header2 />
    <SEO title="Discount" />
    <Discount />
    <Footer />
  </>
)

export default DiscountPage
