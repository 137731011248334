import React, { useState } from "react"
import { navigate } from "gatsby-link"

//icons
import { TbDiscount2 } from "react-icons/tb"
import { FaSpinner } from "react-icons/fa"

// Styles
import styles from "../styles/components/discount.module.scss"

// Form Helpers
function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const Discount = () => {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [tel, setTel] = useState("")
  const [loading, setLoading] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    setLoading(true)

    const form = e.target
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "Discount",
        name,
        email,
        tel,
      }),
    })
      .then(() => {
        setLoading(false)
        navigate(form.getAttribute("action"), {
          state: {
            name: name,
            email: email,
            tel: tel,
          },
        })
      })
      .catch(error => console.log(error))
  }

  // if(loading){
  //   return <h2>Loading</h2>
  // }

  return (
    <>
      <div className={styles.main}>
        <h1 className={styles.title}>Limited Time Discount</h1>
        <h3>
          <TbDiscount2 className={styles.icon} />
          <span>Get 5000/= DISCOUNT </span>
        </h3>
        <h1>On All Annual Memberships</h1>
        <br />
        <p>* Please Visit Us Within Two Weeks To Claim The Discount</p>
        <div className={styles.formContainer}>
          <form
            name="Discount"
            method="post"
            data-netlify="true"
            action="/discount-claimed"
            className={styles.form}
            onSubmit={handleSubmit}
          >
            <p>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  onChange={e => setName(e.target.value)}
                />
              </label>
            </p>
            <p>
              <label>
                Contact Number:
                <input
                  type="tel"
                  name="tel"
                  onChange={e => setTel(e.target.value)}
                />
              </label>
            </p>
            <p>
              <label>
                Email:{" "}
                <input
                  type="email"
                  name="email"
                  onChange={e => setEmail(e.target.value)}
                />
              </label>
            </p>
            <button
              type="submit"
              className={styles.button}
              disabled={!name || !email || !tel || loading}
            >
              {loading ? <FaSpinner className="spinner" /> : "Submit"}
            </button>
          </form>
        </div>
      </div>
    </>
  )
}

export default Discount
